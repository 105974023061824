<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        <template v-if="employee">
          {{ employee.name }}
        </template>
      </h3>
    </div>

    <div class="box-body">
      <div class="row" v-if="employee">
        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="box box-primary">
            <div class="box-body">
              <h5 class="box-title pointer" v-on:click="toggleFormEmployee">
                <a class="collapsed">
                  <i v-bind:class="show_form_employee ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas padding-r-5" />
                  <strong class="d-lg-inline d-md-none">Информация</strong>
                </a>
              </h5>

              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_employee ? 'in' : ''" v-show="show_form_employee"
                     v-show-slide="show_form_employee">

                  <hr class="mb-1 mt-1" />

                  <img v-if="employee.photo_url"
                       class="img-fluid mt-1 mb-1"
                       :src="'https://api.autocredo.com.ua' + employee.photo_url"
                       :alt="employee.title">

                  <hr class="mt-2 mb-1" />

                  <ul class="list-group list-group-unbordered">
                    <li v-if="employee.is_day_off" class="list-group-item font-scale-2">
                      <strong class="text-warning">
                        {{ !employee.is_vacation ? 'Выходной' : 'В отпуске' }}
                      </strong>
                    </li>
                    <li class="list-group-item font-scale-1">
                      <strong>Текущие штрафы</strong>
                      <strong class="pull-right">
                        {{ employee.current_bonuses }}$
                      </strong>
                    </li>
                    <li class="list-group-item font-scale-1">
                      <strong>Телефон</strong>
                      <span class="pull-right">
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + employee.phone" class="font-medium">
                          {{ employee.phone }}
                        </a>
                        <i v-if="employee.telegram_chat_id" class="fab fa-telegram"></i>
                      </span>
                    </li>
                    <li v-if="employee.phone_two" class="list-group-item font-scale-1">
                      <strong>Телефон #2</strong>
                      <span class="pull-right">
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + employee.phone_two" class="font-medium">
                          {{ employee.phone_two }}
                        </a>
                        <i v-if="employee.telegram_chat_id_two" class="fab fa-telegram"></i>
                      </span>
                    </li>
                    <li v-if="employee.email" class="list-group-item font-scale-1">
                      <strong>Email</strong>
                      <span class="pull-right">
                        <i class="fas fa-envelope font-small"></i>
                        <a :href="'mailto:' + employee.email" class="font-medium font-small">
                          {{ employee.email }}
                        </a>
                      </span>
                    </li>
                    <li v-if="employee.branch" class="list-group-item font-scale-1">
                      <strong>Филиал</strong>

                      <a class="pull-right">
                        <strong>{{ employee.branch.title }}</strong>
                      </a>
                      <br />
                      <span class="pull-right font-small">
                        <a class="font-medium">
                          <i class="fas fa-map-marker-alt"></i>
                          {{ employee.branch.address }}
                        </a>
                      </span>
                    </li>
                    <li v-if="employee.department" class="list-group-item font-scale">
                      <a class="pull-right">
                        <strong>{{ employee.department.title }}</strong>
                      </a>
                    </li>
                  </ul>

                  <hr class="mb-2 mt-2" />

                  <p class="text-muted text-right">
                    <small v-if="employee.editor">
                      Обновил <strong>{{ employee.editor.name }}</strong>
                      {{ employee.updated_date }}
                    </small>
                    <small>
                      <br />
                      Создан <strong>{{ employee.created_date }}</strong>
                    </small>
                  </p>
                </div>
              </transition>
            </div>
          </div>

          <div v-if="([1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id))" class="box box-primary">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleFormAddBonuses">
                <a class="collapsed">
                  <i v-bind:class="show_form_add_bonuses ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas" />
                  Добавить штраф
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_add_bonuses ? 'in' : ''"
                     v-show="show_form_add_bonuses" v-show-slide="show_form_add_bonuses">

                  <hr class="mb-3 mt-3" />

                  <form @keydown="form_add_bonuses.onKeydown($event)" @submit.prevent="addBonuses">
                    <div class="row">
                      <div class="col-12" v-bind:class="{ 'has-error': form_add_bonuses.errors.has('amount') }">
                        <div class="form-group">
                          <label>
                            <input :max="100"
                                   :min="1"
                                   class="form-control"
                                   name="amount"
                                   placeholder="Сумма, $"
                                   title="Сумма, $"
                                   type="number"
                                   v-model="form_add_bonuses.amount">
                            <HasError :form="form_add_bonuses" field="amount"/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>

                  <button class="btn btn-success pull-right"
                          type="button"
                          title="Начислить штраф"
                          v-on:click="addBonuses">
                    <strong>Добавить</strong>
                  </button>
                </div>
              </transition>
            </div>
          </div>

          <div v-if="([1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id))" class="box box-primary">
            <div class="box-header mb-0">
              <h5 class="box-title text-center pointer" v-on:click="toggleFormAwayBonuses">
                <a class="collapsed">
                  <i v-bind:class="show_form_away_bonuses ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas" />
                  Списать штраф
                </a>
              </h5>
            </div>
            <div class="box-body">
              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_away_bonuses ? 'in' : ''"
                     v-show="show_form_away_bonuses" v-show-slide="show_form_away_bonuses">

                  <hr class="mb-3 mt-3" />

                  <form @keydown="form_away_bonuses.onKeydown($event)" @submit.prevent="awayBonuses">
                    <div class="row">
                      <div class="col-12" v-bind:class="{ 'has-error': form_away_bonuses.errors.has('amount') }">
                        <div class="form-group">
                          <label>
                            <input :max="100"
                                   :min="1"
                                   class="form-control"
                                   name="amount"
                                   placeholder="Сумма, $"
                                   title="Сумма, $"
                                   type="number"
                                   v-model="form_away_bonuses.amount">
                            <HasError :form="form_away_bonuses" field="amount"/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>

                  <button v-if="employee.current_bonuses > 0"
                          class="btn btn-warning pull-right"
                          type="button"
                          title="Списать штраф"
                          v-on:click="awayBonuses">
                    <strong>Списать</strong>
                  </button>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <vue-tabs class="panel no-border" v-model="currentTabItem" @tab-change="handleTabChange">
            <v-tab class="nav-tab-content"
                   key="history"
                   :title="tabNames.history">
              <employee-history :employeeId="employee.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="bonuses"
                   :title="tabNames.bonuses">
              <employee-bonuses v-if="currentTabItem === 1"
                            :employeeId="employee.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="tasks"
                   :title="tabNames.tasks">
              <employee-tasks v-if="currentTabItem === 2"
                            :employeeId="employee.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="logs"
                   :title="tabNames.logs">
              <employee-logs v-if="currentTabItem === 3"
                             :employeeId="employee.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="notifications"
                   :title="tabNames.notifications">
              <employee-notifications v-if="currentTabItem === 4"
                                      :employeeId="employee.id"/>
            </v-tab>
          </vue-tabs>
        </div>
      </div>
      <loading-processing v-else></loading-processing>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { VTab, VueTabs } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import Form from "vform";

Vue.component('employee-history', (resolve) => {
  return require(['./history'], resolve);
});
Vue.component('employee-bonuses', (resolve) => {
  return require(['./bonuses.vue'], resolve);
});
Vue.component('employee-tasks', (resolve) => {
  return require(['./tasks'], resolve);
});
Vue.component('employee-logs', (resolve) => {
  return require(['./logs'], resolve);
});
Vue.component('employee-notifications', (resolve) => {
  return require(['./notifications'], resolve);
});

Form.axios = API.apiClient;

export default {
  name: "employee-details",

  components: {
    VueTabs,
    VTab,
    Button, HasError, AlertError, AlertErrors,
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  props: {
    employeeId: {
      type: [String, Number],
      required: true,
    },
    currentTab: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    page_title: "Профиль сотрудника",

    employee: null,

    relations: [
      "role",
      "department",
      "branch",
      "city",
      "tasks",
      "logs",
      "notifications",
    ],
    relations_count: [
      "history",
      "tasks",
      "logs",
      "notifications",
    ],

    tabNames: {
      history: "История",
      bonuses: "Штрафы",
      tasks: "Задачи",
      logs: "Действия",
      notifications: "Уведомления",
    },

    currentTabItem: null,

    show_form_employee: true,
    show_form_add_bonuses: true,
    show_form_away_bonuses: true,

    form_add_bonuses: new Form({
      amount: null,
    }),
    form_away_bonuses: new Form({
      amount: null,
    }),
  }),

  async created() {
    try {
      this.EventBus.$on("employee-details-reload", this.getEmployee);

      if (this.currentTab) {
        this.show_form_employee = false;
        this.show_form_add_bonuses = false;
        this.show_form_add_bonuses = false;
        this.show_form_away_bonuses = false;

        this.currentTabItem = this.currentTab;
      }

      await this.getEmployee();
    } catch (error) {
      errorHandler(error);
    }
  },

  mounted() {
    setTimeout(() => {
      this.tabNames.history += " (" + this.employee.history_count + ")";
      this.tabNames.tasks += " (" + this.employee.tasks_count + ")";
      this.tabNames.logs += " (" + this.employee.logs_count + ")";
      this.tabNames.notifications += " (" + this.employee.notifications_count + ")";
    }, 1500);
  },

  methods: {
    handleTabChange(tabIndex, newTab, oldTab) {
      this.currentTabItem = tabIndex;
    },
    async toggleFormEmployee() {
      this.show_form_employee = this.show_form_employee === false;
    },
    async toggleFormAddBonuses() {
      this.show_form_add_bonuses = this.show_form_add_bonuses === false;
    },
    async toggleFormAwayBonuses() {
      this.show_form_away_bonuses = this.show_form_away_bonuses === false;
    },
    async getEmployee() {
      try {
        const employee_response = await API.apiClient.get("/employee/" + this.employeeId, {
          params: {
            relations: this.relations,
            relations_count: this.relations_count,
          },
        });

        this.employee = employee_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async addBonuses() {
      try {
        await this.form_add_bonuses.put('/employee/' + this.employeeId + '/bonuses/add').then(() => {
          showSuccess();
          window.location.reload();
        }).catch((error) => {
          errorHandler(error);
        });
      } catch (error) {
        errorHandler(error);
      }
    },
    async awayBonuses() {
      try {
        await this.form_away_bonuses.put('/employee/' + this.employeeId + '/bonuses/away').then(() => {
          showSuccess();
          window.location.reload();
        }).catch((error) => {
          errorHandler(error);
        });
      } catch (error) {
        errorHandler(error);
      }
    },
  },
};
</script>

<style scoped>

</style>
